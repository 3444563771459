import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const MainContainer = styled.main`
  padding-top: 50px; /* Adjust based on your navbar height */
`;

const Container = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  color: #222;
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const ViewCount = styled.p`
  color: #888;
  font-size: 0.9em;
  margin-bottom: 5px;
`;

const AuthorAndDate = styled.p`
  color: #888;
  font-size: 0.9em;
  margin-bottom: 20px;
  font-style: italic;
`;

const Category = styled.p`
  color: #6a1b9a;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Content = styled.pre`
  color: #555;
  font-size: 1.1em;
  line-height: 1.6;
  white-space: pre-wrap;
`;

function PostDetails({ posts, setPosts }) {
  const { index } = useParams();
  const post = posts[index];

  useEffect(() => {
    if (post && !post.hasIncremented) {
      const updatedPosts = [...posts];
      updatedPosts[index].views = (updatedPosts[index].views || 0) + 1;
      updatedPosts[index].hasIncremented = true; // Flag to indicate increment has occurred
      setPosts(updatedPosts);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [post, index, setPosts]); // Ensure useEffect runs only when necessary

  if (!post) {
    return <p>Post not found</p>;
  }
  return (
    <MainContainer>
      <Container>
        <Title>{post.title}</Title>
       
        <AuthorAndDate>
          {post.author} / {post.date}
        </AuthorAndDate>
        <Category>{post.category}</Category>
        <Content>{post.content}</Content>
      </Container>
    </MainContainer>
  );
}

export default PostDetails;
