// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import NewPost from './components/NewPost';
import GlobalStyles from './styles/GlobalStyles';
import EditFormModal from './components/EditFormModal';
import PostDetails from './components/PostDetails';

function App() {
  const [posts, setPosts] = useState(() => {
    const savedPosts = localStorage.getItem('posts');
    return savedPosts ? JSON.parse(savedPosts) : [];
  });

  const [isAdmin, setIsAdmin] = useState(false);
  const [editingPostIndex, setEditingPostIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: '',
    content: '',
    author: '',
    date: '',
  });

  useEffect(() => {
    if (!Array.isArray(posts)) {
      setPosts([]);
    }
  }, [posts]);

  useEffect(() => {
    localStorage.setItem('posts', JSON.stringify(posts));
  }, [posts]);

  const incrementViewCount = (index) => {
    const updatedPosts = [...posts];
    if (!updatedPosts[index].views) {
      updatedPosts[index].views = 0;
    }
    updatedPosts[index].views += 1;
    setPosts(updatedPosts);
  };

  const handleLogin = () => {
    setIsAdmin(true);
  };

  const handleLogout = () => {
    setIsAdmin(false);
  };

  const addPost = (newPost) => {
    setPosts([newPost, ...posts]);
  };

  const deletePost = (index) => {
    const updatedPosts = posts.filter((_, i) => i !== index);
    setPosts(updatedPosts);
  };

  const editPost = (index) => {
    setEditingPostIndex(index);
    setEditFormData(posts[index]);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const saveEdit = () => {
    const updatedPosts = [...posts];
    updatedPosts[editingPostIndex] = editFormData;
    setPosts(updatedPosts);
    setEditingPostIndex(null);
  };

  return (
    <Router>
      <GlobalStyles />
      <Navbar
        isAdmin={isAdmin}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              posts={posts}
              incrementViewCount={incrementViewCount}
              deletePost={isAdmin ? deletePost : null}
              editPost={isAdmin ? editPost : null}
            />
          }
        />
        <Route path="/about" element={<About />} />
        {isAdmin && (
          <Route path="/new-post" element={<NewPost addPost={addPost} />} />
        )}
        <Route
          path="/post/:index"
          element={
            <PostDetails posts={posts} setPosts={setPosts} />
          }
        />
      </Routes>

      {editingPostIndex !== null && isAdmin && (
        <EditFormModal
          formData={editFormData}
          handleChange={handleEditChange}
          saveEdit={saveEdit}
          cancelEdit={() => setEditingPostIndex(null)}
        />
      )}
    </Router>
  );
}

export default App;
