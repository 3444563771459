import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

// Existing animations
const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

// New shake animation
const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
`;

// Styled components
const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
`;

const Logo = styled(Link)`
  font-size: 1.8em;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333;
    transform: scaleX(0);
    transition: transform 0.3s;
  }
  &:hover::before {
    transform: scaleX(1);
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 1em;
  padding: 8px 16px;
  border-radius: 5px;
  background-color: #28a745;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  &:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
`;

const AdminButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  outline: none;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
`;

const AuthFormContainer = styled.div`
  ${({ show }) =>
    show
      ? css`
          animation: ${slideIn} 0.5s forwards;
        `
      : css`
          animation: ${slideOut} 0.5s forwards;
        `};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 60px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 300px;
`;

// New ShakingContainer styled component
const ShakingContainer = styled(AuthFormContainer)`
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shake} 0.5s;
    `}
`;

const AuthForm = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
`;

const AuthInput = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9em;
  background-color: #f9f9f9;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: 'Poppins', sans-serif;
  &:focus {
    border-color: #28a745;
    box-shadow: 0 0 5px #28a745;
  }
`;

const AuthSubmit = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  font-size: 0.9em;
  font-family: 'Poppins', sans-serif;
  text-align: center;
`;

function Navbar({ isAdmin, handleLogin, handleLogout }) {
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const blockTime = localStorage.getItem('blockTime');
    if (blockTime) {
      const remainingTime = new Date(blockTime) - new Date();
      if (remainingTime > 0) {
        setBlocked(true);
        setTimeLeft(Math.ceil(remainingTime / 1000));
      }
    }
  }, []);

  useEffect(() => {
    if (blocked) {
      const interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setBlocked(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [blocked]);

  const navigate = useNavigate();

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (blocked) return;

    if (username === 'Admin1' && password === 'makepost!') {
      handleLogin(username, password);
      setError(false);
      setShowAuthForm(false);
      setAttempts(0);
    } else {
      setError(true);
      setAttempts((prev) => prev + 1);

      if (attempts + 1 >= 3) {
        setBlocked(true);
        const blockTime = new Date();
        blockTime.setMinutes(blockTime.getMinutes() + 5);
        localStorage.setItem('blockTime', blockTime);
        setTimeLeft(300);
      }
    }
  };

  return (
    <NavbarContainer>
      <Logo to="/" onClick={() => navigate('/')}>
        Investigating Medicine
      </Logo>
      <NavLinks>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        {isAdmin ? (
          <>
            <NavLink to="/new-post">New Post</NavLink>
            <AdminButton onClick={handleLogout}>Logout</AdminButton>
          </>
        ) : (
          <AdminButton onClick={() => setShowAuthForm((prev) => !prev)}>
            Admin
          </AdminButton>
        )}
      </NavLinks>
      {showAuthForm && !isAdmin && (
        <ShakingContainer
          show={showAuthForm}
          shake={error || (blocked && showAuthForm)} // Applying the shake animation when error or blocked
        >
          <AuthForm onSubmit={handleLoginSubmit}>
            <AuthInput
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <AuthInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <AuthSubmit type="submit">Login</AuthSubmit>
          </AuthForm>
          {error && (
            <ErrorMessage>
              Incorrect username or password. Please try again.
            </ErrorMessage>
          )}
          {blocked && (
            <ErrorMessage>
              You are locked out. Try again in {Math.floor(timeLeft / 60)}:
              {('0' + (timeLeft % 60)).slice(-2)} minutes.
            </ErrorMessage>
          )}
        </ShakingContainer>
      )}
    </NavbarContainer>
  );
}

export default Navbar;
