import React, { useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  padding: 40px;
  max-width: 600px;
  margin: 120px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 1em;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;

  &::placeholder {
    color: #bbb;
    font-style: italic;
  }

  &:focus {
    border-color: #4caf50;
    box-shadow: 0 0 10px #4caf50, 0 0 20px #ffffff;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 1em;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;

  &::placeholder {
    color: #bbb;
    font-style: italic;
  }

  &:focus {
    border-color: #4caf50;
    box-shadow: 0 0 10px #4caf50, 0 0 20px #ffffff;
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(90deg, #4caf50, #66bb6a);
  border: none;
  color: #fff;
  padding: 15px 30px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-family: 'Poppins', sans-serif;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(76, 175, 80, 0.3);
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 0.9em;
  margin-top: 10px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

function NewPost({ addPost }) {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState(''); // New state for category

  const handlePasswordCheck = (e) => {
    e.preventDefault();
    if (password === 'makepost!') {
      setIsAuthenticated(true);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPost = {
      title,
      content,
      author,
      date,
      category, // Include category
    };
    addPost(newPost);
    setTitle('');
    setContent('');
    setAuthor('');
    setDate('');
    setCategory(''); // Reset category
  };

  return (
    <FormContainer>
      {!isAuthenticated ? (
        <form onSubmit={handlePasswordCheck}>
          <Input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <SubmitButton type="submit">Authenticate</SubmitButton>
          <ErrorMessage show={error}>Incorrect password! Please try again.</ErrorMessage>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Post Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Category" // Input for category
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <TextArea
            placeholder="Post Content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows="6"
            required
          />
          <SubmitButton type="submit">Add Post</SubmitButton>
        </form>
      )}
    </FormContainer>
  );
}

export default NewPost;
