import React from 'react';
import styled from 'styled-components';

// Styling for the container with a shadow and compact layout
const AboutContainer = styled.div`
  max-width: 700px; /* Make the content more compact */
  margin: 120px auto; /* Center it horizontally with some space from the top */
  padding: 25px 30px; /* Add padding inside the box */
  background-color: #ffffff; /* White background for the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  font-family: 'Poppins', sans-serif; /* Use your desired font */
  line-height: 1.6; /* Adjust line height for readability */
  color: #333; /* Darker text for better contrast */
`;

function About() {
  return (
    <AboutContainer>
      <h1>About <i>Investigating Medicine</i></h1>
      <p>
        Welcome to <strong>Investigating Medicine</strong>! I’m a senior in high school with a passion for making the world of medicine more accessible to students like me. I know firsthand how challenging it can be to find reliable, primary-source information about the medical field. A lot of information we have about it is given in a generic way: <i>"Medicine is a difficult path to pursue, but very rewarding."</i> But, what specifically about pursuing medicine is difficult, what exactly are the rewarding parts of it, and how can one overcome the challenges that come with it?
      </p>
      <p>
        To help answer these questions, I created this page—a platform dedicated to bringing real stories and insights directly to aspiring medical professionals. Here, you’ll find interviews (or overviews of them) with medical students, residents, doctors, surgeons, and healthcare workers sharing their journeys, challenges, and advice. My goal is to provide a transparent, firsthand look into what it means to study and work in medicine, and hopefully help you decide if this is the right path for you.
      </p>
      <p>
        Whether you're considering a future in healthcare or simply curious about the world of medicine, I hope you find these stories as inspiring and informative as I do. Dive in, explore, and let’s navigate the medical field together!
      </p>
    </AboutContainer>
  );
}

export default About;
