import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Apply box-sizing to all elements for better layout control */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Apply smooth scrolling to the entire document */
  html {
    scroll-behavior: smooth;
  }

  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Styles for the scroll container */
  #scroll-container {
    overflow-y: auto; /* Ensure scrolling is enabled */
  }

  /* Adjust the body to add space for the navbar */
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    line-height: 1.6;
    margin-top: 80px; /* Adjust this value based on your navbar height */
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .topTab {
    bottom: 0;
    position: fixed;
    font-size: 25px;
    color: white;
  }

  .pg {
    font-size: 100px;
    height: 100vh;
    background-color: blue;
    text-align: center;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

export default GlobalStyles;
