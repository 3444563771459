// Home.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaEye, FaPencilAlt, FaTrash, FaSearch } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const ViewCount = styled.div`
  display: flex;
  align-items: center;
  color: #888;
  font-size: 0.9em;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const Heading = styled.h2`
  color: #222;
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 1em;
  outline: none;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.3s;

  &:focus {
    border-color: #28a745;
  }
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
`;

const PostList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const PostCardContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const Category = styled.p`
  color: #6a1b9a;
  font-size: 0.85em;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  color: #222;
  font-size: 1.5em;
  margin: 0 0 10px 0;
`;

const AuthorAndDate = styled.p`
  color: #888;
  font-size: 0.9em;
  margin-bottom: 20px;
  font-style: italic;
`;

const ContentPreview = styled.p`
  color: #555;
  font-size: 1em;
  margin: 0 0 20px 0;
  line-height: 1.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  background-color: ${(props) => props.bgColor || '#e74c3c'};
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.hoverColor || '#c0392b'};
  }
`;

function Home({ posts = [], incrementViewCount, deletePost, editPost }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const filteredPosts = posts.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Heading onClick={handleScrollToTop}>Recent Posts</Heading>
      <SearchContainer>
        <SearchBar
          type="text"
          placeholder="Search for a post title..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchIcon />
      </SearchContainer>
      <PostList>
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post, index) => (
            <PostCardContainer key={index}>
              <Category>{post.category}</Category>
              <Title>{post.title}</Title>
              <AuthorAndDate>
                {post.author} / {post.date}
              </AuthorAndDate>
              <ContentPreview>{post.content.slice(0, 100)}...</ContentPreview>
              <ViewCount>
                <FaEye style={{ marginRight: '5px' }} />
                {post.views || 0}
              </ViewCount>
              <ButtonContainer>
                <Link
                  to={`/post/${index}`}
                  style={{ textDecoration: 'none' }}
                  onClick={() => incrementViewCount(index)}
                >
                  <IconButton bgColor="#28a745" hoverColor="#218838">
                    <FaEye />
                  </IconButton>
                </Link>
                {deletePost && editPost && (
                  <>
                    <IconButton
                      bgColor="#f39c12"
                      hoverColor="#e67e22"
                      onClick={() => editPost(index)}
                    >
                      <FaPencilAlt />
                    </IconButton>
                    <IconButton
                      bgColor="#e74c3c"
                      hoverColor="#c0392b"
                      onClick={() => deletePost(index)}
                    >
                      <FaTrash />
                    </IconButton>
                  </>
                )}
              </ButtonContainer>
            </PostCardContainer>
          ))
        ) : (
          <p>No posts found.</p>
        )}
      </PostList>
    </Container>
  );
}

export default Home;
