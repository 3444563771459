import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;

  &.save {
    background-color: #4caf50;
    color: white;
  }

  &.cancel {
    background-color: #e74c3c;
    color: white;
  }

  &:hover {
    opacity: 0.8;
  }
`;

function EditFormModal({ formData, handleChange, saveEdit, cancelEdit }) {
  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Edit Post</h2>
        <Input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Post Title"
        />
        <Input
          type="text"
          name="author"
          value={formData.author}
          onChange={handleChange}
          placeholder="Author"
        />
        <Input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
        />
         <Input
          type="text"
          name="category"
          value={formData.category}
          onChange={handleChange}
          placeholder="Category" // Input for category
        />
        <TextArea
          name="content"
          value={formData.content}
          onChange={handleChange}
          rows="4"
          placeholder="Post Content"
        />
        <ButtonGroup>
          <Button className="save" onClick={saveEdit}>
            Save
          </Button>
          <Button className="cancel" onClick={cancelEdit}>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
}

export default EditFormModal;
